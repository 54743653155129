<template>
    <div class="app flex-row align-items-center">
        <div class="row" style="padding: 0px !important; margin-top: -3px;margin-left: 0px;margin-right: 0px;margin-bottom: 0px;width: 100%">

            <div :class="{'col-md-9' : is_mobile == 'false', 'col-md-12' : is_mobile == 'true' }"  style="padding: 0px ;margin: 0px">
            <div class="jumbotron jumbotron-fluid" id="barra_titulo" ref="barra_titulo" :style="'background-color:#e8eaf6;padding-top: 10px;padding-bottom:10px;margin:0px !important;z-index: 1010;-webkit-box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);\n'+
'        -moz-box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);\n'+
'        box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75); width: calc(' + this.ancho_video +'px + 1.1%)'">
                <div class="container-fluid" style="margin-top: 6px;
            margin-bottom: 0px;">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 v-if="video != null" class="display-4" style="font-size:1.3rem !important;margin-left:10px">{{video.title}}</h4>
                        </div>
                        <div class="col-md-6">


                            <div style="float:right">

                                <div style="float:right;font-size: 1.4em;
                  padding-left: 10px;
                  padding-right: 10px;">

                                    <a v-if="video != null && this.is_mobile == 'false'" ref="enlace_descarga" v-bind:href="'#'"><mdb-badge color="indigo" style="padding: 10px;">Descargar</mdb-badge> </a>
                                </div>
                                <div style="float:right;font-size: 1.4em;
                padding-left: 10px;
                padding-right: 10px;">{{video.likes}}
                                    <mdb-badge :pill="true" color="light-blue">
                                        <div @click="LikeVideo">
                                            <mdb-icon v-if="check_like == 0"  far icon="heart"></mdb-icon>
                                            <mdb-icon v-else fas icon="heart"></mdb-icon>
                                        </div>
                                    </mdb-badge>
                                </div>


                <div v-if="video != null" style="float:right;font-size: 1.4em;
                padding-left: 10px;
                padding-right: 10px;">{{video.views}}
                    <mdb-badge :pill="true" color="indigo">
                        <mdb-icon far icon="eye"></mdb-icon>
                    </mdb-badge>
                </div>

            </div>

        </div>
        </div>
        </div>
        </div>

                <div classs="container">

                <center>
                    <video  id="video" v-bind:style="'padding-top:20px;width: ' + (ancho_video-0.20*ancho_video) + 'px !important' + margin_ismobile" v-bind:src="url_descarga"  vref="video" controls></video>
                </center>

                </div>


        </div>


            <div v-if="this.is_mobile == 'false'" :style="'position: fixed;right: 0px;height: 100%;background: #c5cae9;margin-top: -5px;width:'+(ancho_sidebar - 16)+'px' ">
                <mdb-row class="" style="padding-left: 30px">
                    <b-row style="width: 100%;height: 200px; background: -moz-linear-gradient(top, white, rgb(226, 227, 234));">
                        <mdb-row class="mt-3 align-items-center justify-content-start" style="padding-left: 50px; padding-right: 50px;">
                            <img v-if="video != null" v-bind:src="video.delegacion.icono" v-bind:width="80" style="border-radius: 150px">
                            <h2 v-if="video != null" style="margin-left: 15px">{{video.delegacion.nombre}}</h2>
                        </mdb-row>

                    </b-row>
                </mdb-row>

                <b-row class="mt-3 align-items-center justify-content-start" style="padding-left: 30px; padding-right: 30px;">
                    <b-card v-if="video != null" header="Descripción del Vídeo" style="width: 100%">
                        {{video.details}}
                    </b-card>
                </b-row>


            </div>

            <div v-if="this.is_mobile == 'true'"  style="background: rgb(249, 250, 255);padding-bottom: 90px;width: 100%">
                <mdb-row class="" style="padding-left: 30px">
                    <b-row style="width: 100%;height: 200px; background: #dfdfdf;">
                        <mdb-row class="mt-3 align-items-center justify-content-start" style="padding-left: 50px; padding-right: 50px;">
                            <img v-if="video != null" v-bind:src="video.delegacion.icono" v-bind:width="80" style="border-radius: 150px">
                            <h2 v-if="video != null" style="margin-left: 15px">{{video.delegacion.nombre}}</h2>
                        </mdb-row>

                    </b-row>
                </mdb-row>

                <b-row class="mt-3 align-items-center justify-content-start" style="padding-left: 30px; padding-right: 30px;">
                    <b-card v-if="video != null" header="Descripción del Vídeo" style="width: 100%">
                        {{video.details}}
                    </b-card>
                </b-row>


            </div>


        </div>
    </div>


</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbMediaImage,
        mdbBadge,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';
    import 'video.js/dist/video-js.css'
    import { videoPlayer } from 'vue-video-player';

    export default {
        name: 'Home',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbBtn,
            mdbBadge,
            mdbCardGroup,
            videoPlayer,
            mdbMediaImage,
        },
        data() {
            return {
                url_descarga : '',
                novedades : [],
                pagina_actual : 1,
                esta_en_vertical: false,
                margin_ismobile: '',
                marcador_likes: 0,
                check_like: 0,
                is_mobile:'false',
                screenHeight:0,
                screenWidth: 0,
                ancho_video: ((window.innerWidth/12)*9),
                ancho_barra: ((window.innerWidth/12)*9),
                ancho_sidebar : ((window.innerWidth/12)*3),
                video: {
                    id : 0,
                    title : '',
                    delegacion : {
                        icono : '',
                        nombre : '',
                    },
                    details : '',
                    views : '',
                    likes : '',


                },
                responsive : true,
                playerOptions: {
                    // videojs options
                    muted: false,
                    language: 'es',
                    height: window.innerHeight - 178,
                    width: ((window.innerWidth/12)*9),
                    sources: [{
                        name:'primario',
                        type: "video/mp4",
                        src: 'https://agustinostv-front.archive.tornadocore.es/v2/index.php/video/reproducir?id='+this.$route.params.id+'&tipo=' + localStorage.getItem('res_video') + '&token='+ localStorage.getItem('token'),
                    }],
                    poster: 'https://api.agustinos.tv/api/imagenes/video/'+this.$route.params.id + '/'+ localStorage.getItem('token') + '?w=1280',
                },
            }
        },

        methods: {

            LikeVideo()
            {
                console.log('like');
                if(this.video != null)
                {
                    if(this.check_like == 0) {
                        ApiService.AddLikeVideo(this.video.id).then(r => {
                            this.video.likes = r.data;
                            this.check_like =1;
                        });
                    }
                    else if(this.check_like == 1)
                    {
                        ApiService.QuitLikeVideo(this.video.id).then(r => {
                            this.video.likes = r.data;
                            this.check_like =0;
                        });

                    }
                }
            },
            onPlayerLoadeddata(e)
            {

                    console.log('hola');
                    if(!localStorage.getItem('tam_video')) {
                            if (this.is_mobile == 'false') {
                                //this.ancho_video = document.getElementById('vjs_video_1').clientWidth;
                                localStorage.setItem('tam_video', this.ancho_video);
                               // this.ancho_barra = ((window.innerWidth/12)*9);
                            } else {
                               // this.ancho_barra = window.innerWidth;
                            }


                        console.log('EJECUTADO EVENTO ONPLAYERLOADDATA');
                        console.log('ancho video : ' + this.ancho_video);
                        console.log('ancho barra : ' + this.ancho_barra);
                    }
            },


        },
        created(){

            if(window.innerHeight > window.innerWidth)
            {
                this.esta_en_vertical = true;
                document.querySelector("html").style.fontSize = '.77rem';
            }

            ApiService.VerVideo(this.$route.params.id).then(response => {
                console.log('video');
                console.log(response);


                if(response.data.estado != 'COMPLETED') {
                    this.$swal.fire({
                        title: 'El vídeo introducido no existe o no está listo para reproducirse en estos momentos',
                        type: 'error',
                        showCancelButton: false,
                        timer: 3000,
                    });

                    this.$router.push({path: '/home'});
                    return;
                }

                this.video = response.data;



                ApiService.getImagenVideo(this.video.id).then( r => {
                    console.log('imagen video');
                   console.log(r);
                });
                ApiService.addViewtoVideo(this.video.id).then( r => {
                    console.log(r);
                });
                ApiService.checkLikeVideo(this.video.id).then(r => {
                    console.log('check like : ');
                    this.check_like = r.data;
                    console.log(this.check_like);
                });
                ApiService.isMobile().then(response => {
                    console.log('Ejecutando IsMobile ...');
                    console.log(response);
                    this.is_mobile = response.data.is_mobile.toString();
                    


                    if(this.is_mobile == 'true')
                    {
                        this.ancho_video = window.innerWidth;
                        this.margin_ismobile = ';margin-top:-15px';
                    }
                    if(localStorage.getItem('is_mobile'))
                    {

                        this.is_mobile = localStorage.getItem('is_mobile');
                        console.log('leyendo is_mobile : ' + this.is_mobile );

                    }

                    else {
                        console.log('asignando is_mobile = ' + this.is_mobile);
                        localStorage.setItem('is_mobile', this.is_mobile);
                        if(this.is_mobile == true) {
                            localStorage.setItem('res_video', '360p');
                        }
                        else {
                            localStorage.setItem('res_video', '720p');
                        }
                        this.$forceUpdate();
                    }

                    console.log('Recalculando tamaños : ');
                    if (localStorage.getItem('tam_video')) {
                        console.log('tam video : ');

                       // this.ancho_video = localStorage.getItem('tam_video');
                        console.log(this.ancho_video);
                    } else {
                        //Generar TAMVideo y recargar
                        this.screenHeight = window.innerHeight;
                        this.screenWidth = window.innerWidth;
                        console.log('Screen height' + this.screenHeight);
                        console.log('Screen Width' + this.screenWidth);

                        if(this.is_mobile == 'false') {
                           // this.ancho_barra = ((window.innerWidth/12)*9);
                        }
                        else
                        {
                            this.ancho_barra = window.innerWidth;
                        }
                        localStorage.setItem('tam_video', this.ancho_video);
                        this.$forceUpdate();

                    }
                });
            });








        },
        mounted()
        {
            console.log('hola');
            this.url_descarga = 'https://agustinostv-front.archive.tornadocore.es/v2/index.php/video/reproducir?id='+this.$route.params.id+'&tipo=' + localStorage.getItem('res_video') + '&token='+ localStorage.getItem('token');
            console.log(this.$refs.enlace_descarga);
            this.$refs.enlace_descarga.href = this.url_descarga;
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }

</style>
